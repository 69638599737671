var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'py-3': !_vm.mobile, 'py-0': _vm.mobile },attrs:{"id":_vm.name}},[_c('div',{class:[
      _vm.orientationClass(
        'px-0 py-0',
        'd-md-inline-flex align-center px-0 py-0'
      ),
      'col-12'
    ]},[_c('padding',{attrs:{"classes":_vm.classes}}),(!_vm.hideLabel)?_c('div',{class:[_vm.orientationClass('', _vm.layout), 'd-flex mr-3 py-0']},[_c('span',_vm._g({},
          _vm.rightAccessAudittrail
            ? { click: () => _vm.$emit('itemselected', _vm.name) }
            : {}
        ),[_c('span',{staticClass:"d-flex flex-row align-center"},[_vm._t("pre-wording"),_c('lang-label',{class:[_vm.infoClass('font-italic text-dark', _vm.audittrailClass)],attrs:{"labels":_vm.wording}})],2)]),_vm._t("header")],2):_vm._e(),_c('div',{class:[
        _vm.orientationClass('col-12 d-flex pb-0', 'col'),
        'd-md-inline-flex',
        'value-input',
        'px-0',
        'py-0',
        'align-center'
      ]},[_c('div',{staticClass:"col px-1 px-md-3 py-0"},[(!_vm.isInfo)?_c('value-input',{attrs:{"disabled":_vm.disabled,"value":_vm.value,"name":_vm.name,"type":_vm.type,"metadata":_vm.metadata,"labels":_vm.wording,"classes":_vm.classes},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)}}}):_c('lang-label',{attrs:{"labels":_vm.value}}),(_vm.hideLabel)?_vm._t("invalid-feedback"):_vm._e()],2),(!_vm.isInfo)?_vm._t("append"):_vm._e()],2),_c('div',[(_vm.isEpro)?_vm._t("invalid-feedback"):_vm._e(),_vm._t("footer")],2)],1),_c('div',{staticClass:"px-4"},[(!_vm.isEpro && !_vm.hideLabel)?_vm._t("invalid-feedback"):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }